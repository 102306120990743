export const BANNER_TYPE = {
  PureImage: 1, // 清晰沉浸式
  AutoGoods: 2, // 自动聚合banner样式
  AutoGoodsA: 3, // 自动聚合banner A 样式
  AutoGoodsB: 4, // 自动聚合banner B 样式
  AutoGoodsC: 5, // 自动聚合banner C 样式
  Trends: 10000, // 趋势时尚帧
  TrendsB: 10002, // 趋势时尚fashion2样式
  TrendsC: 10003, // 趋势时尚fashion3样式
}
