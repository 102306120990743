<template>
  <div 
    class="trends-material"
    :style="containerStyle"
  >
    <trend-icon 
      class="trends-material__image-container"
      :css-right="cssRight"
    >
      <template #trendIcon>
        <img
          :src="bannerItem.titleIconImage?.src"
          :style="{ width: 'auto', height: '.4533rem' }"
        />
      </template>
    </trend-icon>
    <div class="trends-material__word">
      <p 
        class="trends-material__word-text"
        :data-arrow="`${cssRight ? 'left' : 'right'}`"
        :style="{
          '-webkit-line-clamp': bannerItem.trendWord?.includes(' ') ? 2 : 1,
          ...(
            textOverFlow ?
              (cssRight? { 'padding-left': '23px' } 
                : { 'padding-right': '23px' })
              : {}
          ),
        }"
      >
        {{ bannerItem.trendWord }}
      </p>
      <span 
        v-if="textOverFlow"
        class="trends-material__word-arrow"
      >
      </span>
      <BImage
        :src="trendWordBackground"
        :ratio="9.82"
        :firstScreen="true"
        style="position: absolute;width: 2.88rem;height: .2933rem;"
        class="trends-material__word-background"
      />
    </div>
  </div>
</template>

<script setup>
import { SImage as BImage } from '@shein-aidc/sui-image/mobile'
import TrendIcon from './TrendIcon'
import { ref, onMounted } from 'vue'

const props = defineProps({
  bannerItem: {
    type: Object,
    default: () => ({})
  },
  cssRight: {
    type: Boolean,
    default: false
  },
})

const trendWordBackground = props.cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2025/01/15/78/1736912528d88e32b7dbd33361fa49acb2ca6ca453.webp' : 'https://img.ltwebstatic.com/images3_ccc/2025/01/09/a0/17364159335699ad2c086a9535a07a301ef46e69ea.webp'

const containerStyle = {
  ...(+props?.bannerItem?.bannerType !== 10000 ? (props.cssRight ? 
    { paddingRight: '0.8rem' } : { paddingLeft: '0.8rem' }) :
    {}),
  ...props.cssRight ? { left: `${props.bannerItem.trendRightOffset / 37.5}rem` } : { right: `${props.bannerItem.trendRightOffset / 37.5}rem` },
}
const textOverFlow = ref(false)
onMounted(()=>{
  const textEl = document.querySelector('.trends-material__word-text')
  if(textEl?.scrollHeight > textEl?.offsetHeight){
    textOverFlow.value = true
  }
})
</script>

<style lang="less">
.trends-material {
  position: absolute;
  top: calc(50% - 8px);
  transform: translateY(-50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 5.68rem;

  &__image-container {
    position: relative;
    align-self: flex-start;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: .6667rem;
    //padding: .1067rem .2667rem;
    border-radius: .5333rem;
    margin-bottom: .2133rem;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &__word {
    display: inline-flex;
    position: relative;
    max-width: 5.0933rem;
    direction: ltr;
    align-self: flex-start;

    &-text {
      z-index: 4;
      color: #fff;
      word-break: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-size: .48rem;
      font-style: normal;
      font-weight: 700;
      max-width: 5.0933rem;
      line-height: .64rem;
      .line-camp(2);
    }
    &-text::after, &-arrow {
      content: '';
      display: inline-block;
      margin-left: .1067rem;
      white-space: nowrap;
      width: .32rem;
      height: .32rem;
      background: url('https://img.ltwebstatic.com/images3_ccc/2025/02/08/e0/1739004175e19ac69476380f8041df616ab6c35e06.webp') no-repeat center;
      background-size: contain;
    }
    &-arrow {
      position: absolute;
      flex-shrink: 0;
      right: 10px;
      bottom: 5px;
      margin-left: 0;
      [mir=rtl] & {
        transform: rotate(180deg);
      }
    }
    &-text[data-arrow="left"]::after {
      transform: rotate(180deg);
    }
    &-text[data-arrow="right"]::after {
      transform: rotate(0deg);
    }
    &-icon {
      position: absolute;
    }
    &-background {
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}
</style>
