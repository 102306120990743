
<script lang="jsx">
import { defineComponent } from 'vue'
import { BANNER_TYPE } from './constants'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'

export default defineComponent({
  name: 'ImmersiveStaticBg',

  props: {
    item: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    cutImg: {
      type: Function,
      default: () => {}
    },
    realBannerHeight: {
      type: Number,
      default: 0
    },
  },
  render({ $props: props }) {
    const { item, activeIndex, realBannerHeight, } =  props
    return (
      <div class="c-immersiveBanner__static-swiper">
        {/* 渲染单个div */}
        <div
          class="c-immersiveBanner__static-slide"
          style={{
            transform: `translateY(-${activeIndex * realBannerHeight}rem)`
          }}
        >
          { 
            item.map((it) => (
              <div
                class={{ 
                  'c-immersiveBanner__bgImage': true, 
                }}
                style={{
                  backgroundImage: (Number(it.bannerType) === 2 && it.themeColor) ? `url(${ it.image.src }), ${ it.bannerBgColor }` : `url(${ it.image.src })`,
                  height: `${ realBannerHeight }rem`,
                }}
              >
                {[BANNER_TYPE.TrendsB, BANNER_TYPE.TrendsC].includes(+it.bannerType) && <BaseImg
                  placeholder={{
                    width: it?.bottomImage?.width,
                    height: it?.bottomImage?.height,
                  }}
                  ratio={it?.bottomImage?.ratio}
                  class={'immersive-banner__bottom-image'}
                  first-screen={ true }
                  is-support-webp={ true }
                  img-src={ it?.bottomImage?.src }
                  style={ { position: 'absolute' } }
                />}
              </div>
            ))
          }
        </div>
      </div>
    )
  },
})
</script>
<style lang="less">
 .immersive-banner__bottom-image {
   height: 1rem;
   z-index: 9;
   bottom: 0;
 }
</style>
