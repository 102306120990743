<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div
    v-if="showItems.length > 0"
    ref="immersiveBannerRef"
    class="c-immersiveBanner-new__area has-bottom-space"
    :style="{
      position: 'relative',
      marginTop: `-${commonHeight?.topBgHeight + commonHeight?.moveUpHeight}rem`,
      zIndex: '1',
      '--offset-top': `${commonHeight?.topBgHeight + commonHeight?.moveUpHeight}rem`,
      '--old-real-banner-height': `${commonHeight?.bottomBgHeight - commonHeight?.moveUpHeight}rem`
    }"
  >
    <div class="c-immersiveBanner-new__wrap">
      <!--        底部透明占位-->
      <div
        class="c-immersiveBanner-new__trans-placeholder"
        :style="{ backgroundColor: globalBgColor || '#fff' }"
      ></div>
      <!-- 真实滑动轮播图 -->
      <div
        class="c-immersiveBanner-new__real"
        :style="{
          width: '10rem',
          height: `${commonHeight?.realBannerHeight}rem`,
        }"
      >
        <swiper-container
          ref="realBannerSwiper"
          init="false" 
          destroy-on-disconnected="false"
          :dir="swiperDir"
          class="c-immersiveBanner-new__real-swiper"
          @click="handleSlideClick"
        >
          <swiper-slide
            v-for="(item, index) in showItems"
            :key="`${item.FE_ID}`"
            v-tap="getAnalysisData('2-22-2', { item: analysisCateItem(item, 'click'), index, useBffApi: true })"
            v-expose="getAnalysisData('2-22-1', { item: analysisCateItem(item, 'expose'), index, useBffApi: true })"
            :data-key="`${item.FE_ID}`"
            :data-realswiperIndex="index"
            class="c-immersiveBanner-new__real-slide"
            :class="{
              'c-immersiveBanner-new__list-recommend-out': isListRecommendMainImageReplace
            }"
          >
            <!-- 渐变色 -->
            <div
              v-if="Number(item.bannerType) === 2 && item.themeColor"
              class="c-immersiveBanner-new__bgImage gradientColor"
              :style="{
                backgroundImage: `${item.bannerBgColor}`,
                height: `${commonHeight?.realBannerHeight}rem`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: `10rem auto`,
                overflow: 'hidden',
              }"
            >
            </div>
            <!-- "蒙层" 或 背景图 -->
            <div
              class="c-immersiveBanner-new__bgImage"
              :class="{'fsp-element': item.FE_ID == showItems[0].FE_ID}"
              :data-background-image="index === 0 ? (item?.image?.src ?? '') : null"
              :style="{
                backgroundImage: `url(${ item.image.src })`,
                height: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: `10rem auto`,
                overflow: 'hidden',
                zIndex: 2,
              }"
            >
            </div>
            <BaseImg
              v-if="[BANNER_TYPE.TrendsB,BANNER_TYPE.TrendsC].includes(+item.bannerType)"
              class="trend-bottom__image"
              :img-design-width="375 * 2"
              :first-screen="true"
              :placeholder="{
                width: item?.bottomImage?.width,
                height: item?.bottomImage?.height,
              }"
              :img-src="item?.bottomImage?.src"
            />
            <!-- 自动聚合 -->
            <ImmersiveProduct
              v-if="Number(item.bannerType) === 2"
              :item="item"
              :index="index"
              :context="context"
              :positionTypeMap="positionTypeMap"
              :data-key="`${index}_${positionTypeMap[`${item.positionType}`][0]}_0`"
              :textBtnColor="textBtnColor"
              :cssRight="cssRight"
              :item-config="productItemConfig"
              :isR56="isH1R56"
              :clickProduct="clickProduct"
              :analysisProductItem="analysisProductItem"
              :bannerIns="bannerIns"
              :offset-top="commonHeight?.topBgHeight + commonHeight?.moveUpHeight"
            />
            <ImmersiveNewProduct
              v-if="newBannerType.includes(Number(item.bannerType))"
              :id="Number(item.bannerType) === 3 && 'autoGoodsAProduct'"
              :item="item"
              :index="index"
              :context="context"
              :bannerType="Number(item.bannerType)"
              :cssRight="cssRight"
              :item-config="productItemConfig"
              :clickProduct="clickProduct"
              :analysisProductItem="analysisProductItem"
              :bannerIns="bannerIns"
              :isH1AutoACarousel="isH1AutoACarousel"
              :currentProductIndex="currentProductIndex"
              :offset-top="commonHeight?.topBgHeight + commonHeight?.moveUpHeight"
            />
            <template v-if="item?.bannerImage?.src">
              <img
                v-if="index === 0"
                :src="item?.bannerImage?.src"
                class="c-immersiveBanner-new__gif-banner not-fsp-element"
                no-format
              />
              <img
                v-else
                :data-src="item?.bannerImage?.src"
                class="c-immersiveBanner-new__gif-banner not-fsp-element lazyload"
                data-exp="useWebp=0"
              />
            </template>
            <BaseImg
              v-if="item?.topBannerImage?.src"
              :first-screen="true"
              :is-support-webp="true"
              :img-design-width="359 * 3" 
              :img-src="item?.topBannerImage?.src"
              position="left top"
              fit="cover"
              class="c-immersiveBanner-new__bgLayer"
              :style="cutImgStyle(item)"
              style="position: absolute;width: calc(10rem - 16px);"              
            />

            <!-- 时尚帧趋势物料信息 -->
            <TrendsMaterial
              v-if="[BANNER_TYPE.Trends, BANNER_TYPE.TrendsB, BANNER_TYPE.TrendsC].includes(Number(item.bannerType))"
              :banner-item="item"
              :cssRight="cssRight"
            />
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick, defineAsyncComponent, hydrateOnVisible } from 'vue'
// common
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { transfromAndCutImg } from 'public/src/services/resource'
import exposeService from 'public/src/services/expose/index.js'
// components
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import TrendsMaterial from './trends/TrendsMaterial.vue'
import { handleColor, hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { register } from 'swiper/element'
import { Autoplay, Pagination } from 'swiper/modules'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
import { BANNER_TYPE } from './constants.js'

typeof window !== 'undefined' && register()

const AutoGoodsType = [BANNER_TYPE.AutoGoods, BANNER_TYPE.AutoGoodsA, BANNER_TYPE.AutoGoodsB, BANNER_TYPE.AutoGoodsC, BANNER_TYPE.Trends, BANNER_TYPE.TrendsB, BANNER_TYPE.TrendsC]

const { PUBLIC_CDN } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
let bodyEl = null
let isUserScroll = true

const ErrorType = {
  ItemError: 'itemError',
  ItemImageError: 'itemImageError',
  GoodsNumError: 'goodsNumError',
  TypeError: 'typeError',
  ItemImageHeightError: 'itemImageHeightError'
}

export default defineComponent({
  name: 'ImmersiveBanner',
  directives: {
    expose,
    tap,
  },
  components: {
    ImmersiveProduct: defineAsyncComponent({
      loader: () => import('./products/ImmersiveProduct.vue'),
      hydrate: hydrateOnVisible(),
    }),
    BaseImg,
    ImmersiveNewProduct: defineAsyncComponent({
      loader: () => import('./products/ImmersiveNewProduct.vue'),
      hydrate: hydrateOnVisible(),
    }),
    TrendsMaterial,
  },
  mixins: [mixins],
  props: {
    ...commonProps, 
    activeChannelId: {
      type: Number,
      default: 0
    }
  },
  data() {
    const cssRight = this.context?.GB_cssRight || false
    const { backgroundColor } = this.context?.extension ?? {}
    const globalBgColor = backgroundColor ? hexToRGBA(handleColor(backgroundColor)) : ''
    const { metaData } = this.propData?.props ?? {}
    const disableBottomSpacing = metaData?.disableBottomSpacing
    const { H1PictureProcess, H1AutoACarousel, H1FrameGap } = this.context?.abtInfoObj ?? {}
    const isH1R56 = H1PictureProcess === 'H1_picture'
    const isH1AutoACarousel = H1AutoACarousel !== 'false'
    const needSwiper = this.propData?.props?.items?.length > 1
    const { items: showItems, commonHeight } = this.initImmersiveBannerData(this.propData.props?.items ?? [])
    return {
      cssRight,
      loadComplete: false,
      showItems,
      isShowComp: showItems.length > 0,
      swiperDir: cssRight ? 'rtl' : 'ltr', // 设置swiper方向
      realSwiperInstance: null,
      realBannerSwiperRef: null,
      observerInstance: null,
      inViewport: false, // 是否在视口内
      realSwiperBackTop: true, // 真实轮播回到了顶部，需要将header+tab置为透明色
      positionTypeMap: {
        '1': ['text', 'p1', 'p2'],
        '2': ['p1', 'p2', 'text'],
        '3': ['p1', 'text', 'p2']
      }, // 展示位置（1: 文字在左，商品在右, 2: 文字在右，商品在左, 3: 文字居中，商品两侧）
      resizeObserver: null, // 屏幕尺寸发生变化时监听
      lastHtmlFontSize: 0,
      isListRecommendMainImageReplace: false,
      newBannerType: [BANNER_TYPE.AutoGoodsA, BANNER_TYPE.AutoGoodsB, BANNER_TYPE.AutoGoodsC, BANNER_TYPE.Trends, BANNER_TYPE.TrendsB, BANNER_TYPE.TrendsC],
      productItemConfig: {
        disableMainimgJump: true,
        showCamelPrice: false,
        showSheinClubPriceOnSale: false,
        noNeedAnyEstimatedPriceTag: true, // 不需要任何到手价标签
        showNewFlashNewLabel: false,    // 不展示新型闪购文案标签
        showSheinClubNewLabel: false,  // 不展示付费会员文案标签
      },
      bannerIns: this,
      isPreviewPage: false,
      globalBgColor,
      disableBottomSpacing,
      isH1R56,
      needSwiper,
      commonHeight,
      currentProductIndex: 0, // 自动聚合样式 A 商品轮播当前商品索引
      autoGoodsAProductObserver: null, // 自动聚合样式 A 商品是否在视口内
      switchPlay: false, // 自动聚合样式 A 商品轮播开关
      isH1AutoACarousel, // 自动聚合样式 A 商品轮播实验开关
      H1FrameGap: Number(H1FrameGap) || 5, // 沉浸式轮播帧间隔
      intervalTime: Number(H1AutoACarousel) || 1, // 自动聚合样式 A 商品轮播间隔
      BANNER_TYPE,
    }
  },
  computed: {
    isCurrentChannelInstance() {
      return Number(this.activeChannelId) === Number(this.context?.channelId)
    },
  },
  watch: {
    switchPlay: {
      handler(val) {
        if (val) {
          this.switchAnimationStart()
        }
      },
      immediate: true
    },
    realSwiperBackTop: {
      handler(flag) {
        if (typeof window === 'undefined') {
          return
        }
        if (bodyEl && bodyEl.classList.contains('S-popup-parent__hidden')) {
          // 弹窗模式
          return
        }
        flag ? this.handleEnterViewport() : this.handleLeaveViewport()
      }
    },
    activeChannelId(val) {
      const currentActivated = val === this.context?.channelId
      currentActivated ? 
        cccxEventBus.on('page-scroll', this.onScroll, this.code) : 
        cccxEventBus.off('page-scroll', this.code)
      if (!currentActivated) return
      
      this.addResizeListener()
    },
  },
  created() {
    // 服务端客户端都计算
    this.handleInitSwiperData() 
  },
  async mounted() {
    // 获取当前根元素的font-size
    this.initRealBannerSwiper()
    this.isPreviewPage = !!this.$route.query?.contentId
    this.lastHtmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) || 0
    bodyEl = document.body
  },
  activated() {
    requestAnimationFrame(() => {
      this.observeAutoGoodsAProduct()
    })
    if (!this.isCurrentChannelInstance) {
      return
    }
    
    isUserScroll = false
    // -1表示浏览器回退，0表示新开页面，1表示浏览器前进
    // 浏览器回退会保留滚动距离，新开页面和浏览器前进都会滚动到顶部
    // 详情查看public/src/pages/product_app/router/index.js
    if ([0, 1].includes(Number(window?.appRouteExtData?.direction))) {
      this.realSwiperBackTop = true
      requestIdleCallback(() => {
        this.realSwiperInstance?.autoplay?.start?.()
      })
    }
    
    this.addResizeListener()
    this.lastHtmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) || 0
  },
  deactivated() {
    if (!this.isCurrentChannelInstance) {
      return
    }
    // NOTE: 能否放在顶层 home.vue 中处理，（头部状态的还原来）
    this.clearResizeListener()
  },
  beforeUnmount() {
    // 销毁 swiper 实例 和 observer 实例
    this.realSwiperInstance?.destroy()
    delete this.realSwiperInstance

    this.observerInstance?.destroy?.()
    this.observerInstance = null
    cccxEventBus.off('page-scroll', this.code)
  },
  unmounted() {
    this.clearResizeListener()
    this.autoGoodsAProductObserver = null
  },
  methods: {
    // 自动聚合样式 A 商品是否在视口内
    observeAutoGoodsAProduct() {
      if (!this.isH1AutoACarousel) return
      const topHide =  88
      const bottomHide = 47
      this.autoGoodsAProductObserver = new exposeService({ observeHide: true, exposeRatio: 0.99, mask: [topHide, '0', bottomHide, '0']  })
      this.autoGoodsAProductObserver.observe({
        elements: document.getElementById('autoGoodsAProduct'),
        once: false,
      }, ({ exposeDoms }) => {
        if (exposeDoms.length) {
          this.switchPlay = true
        } else {
          this.switchPlay = false
        }
      })
    },
    // 启动自动聚合样式 A 轮播
    switchAnimationStart() {
      if (!this.isH1AutoACarousel) return
      const animation = setInterval(() => {
        if (!this.switchPlay) {
          clearInterval(animation)
          return
        }
        if (this.currentProductIndex >= 1) {
          this.currentProductIndex = 0
        } else {
          this.currentProductIndex = this.currentProductIndex + 1
        }
      }, this.intervalTime * 1000)
    },
    initRealBannerSwiper() {
      // swiper element
      const swiperEl = this.$refs.realBannerSwiper
      if (!swiperEl) return
      const paginationOption = this.needSwiper ? {
        pagination: {
          enabled: true,
        },
        injectStylesUrls: [
          PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css',
        ],
        injectStyles: [
          `
          .swiper-pagination{
            line-height: 0;
            bottom: 10px !important;
          }
          .swiper-pagination-bullets {
            transform: scale(0.5) translate3d(0,0,0) !important;
            -webkit-transform: scale(0.5) translate3d(0,0,0) !important;
          }
          .swiper-pagination .swiper-pagination-bullet{
            background: #fff;
            border: 1px solid #bbb;
            margin: 0 2px !important;
          }
          .swiper-pagination .swiper-pagination-bullet-active{
            background: rgba(255,255,255,0.8);
            border: 1px solid #bbb;
            width: 0.5133333rem;
            height: 0.193333rem;
            border-radius: 0.21333333rem;
          }
        `,
        ],
      } : {}

      // swiper parameters
      const swiperParams = {
        direction: 'horizontal',
        loop: this.showItems?.length > 1,
        modules: [Autoplay, Pagination],
        autoplay: {
          delay: this.H1FrameGap * 1000,
          disableOnInteraction: false
        },
        resizeObserver: true,
        spaceBetween: 0,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        autoplayOutsideControl: true,
        resistance: false,
        ...paginationOption,
      }
      
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      
      this.handleRealSwiperInit(swiperEl?.swiper)
      this.initRealSwiperObserver(swiperEl, swiperEl?.swiper)
    },
    initRealSwiperObserver(swiperEl, swiperInstance) {
      this.observerInstance = new exposeService({ observeHide: true })
      this.observerInstance.observe({
        elements: swiperEl,
        once: false,
      }, ({ exposeDoms }) => {
        if (!swiperInstance) return

        if (exposeDoms.length) {
          // 进入视口
          this.inViewport = true
          this.playSwiperAutoplay()
        } else {
          // 离开视口
          this.handleLeaveViewport()
        }
      })
    },
    cutImgStyle(item) {
      // 判断图层是否需要裁剪, 手动消除裁剪差异，设置图片宽高往右下移动1px
      const defaultWidth = 359 * 3
      const defaultHeight = 138 * 3
      const isCut = item?.topBannerImage?.width > defaultWidth || item?.topBannerImage?.height > defaultHeight
      if (isCut) {
        return {
          width: `calc(${item?.topBannerImage?.width / 3 / 37.5 + 'rem'} + 1px)`,
          height: `calc(3.68rem - 1px)`,
        }
      }
      return {}
    },
    cutImg(imgUrl, forceTransform) {
      const { RESOURCE_SDK } = this.context
      const { deviceData = '', isSupportWeb = '', sceneMap = {}, isSupprotCut = '' } = RESOURCE_SDK || {}
      if (!isSupportWeb) return imgUrl
      const cutData = {
        deviceData,
        isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
        isSupprotCut: forceTransform ? true : isSupprotCut,
        imgUrl,
        designWidth: 950,
        sceneMap,
        exp: 'unCut=0#max=950',
      }
      return transfromAndCutImg(cutData)
    },
    addResizeListener() {
      if (this.resizeObserver) {
        this.resizeObserver?.disconnect()
      }

      const config = {
        attributes: true,
        attributeFilter: ['style']
      }
      const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
          const currentHtmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) || 0
          if (mutation.type === 'attributes' && mutation.attributeName === 'style' && currentHtmlFontSize !== this.lastHtmlFontSize) {
            this.lastHtmlFontSize = currentHtmlFontSize
            // 屏幕尺寸发生变化后会重新初始化 realswiper，回到第一帧，背景图也需要回到第一帧
            this.realSwiperInstance?.slideTo?.(0, 0)
          }
        }
      }

      this.resizeObserver = new MutationObserver(callback)
      this.resizeObserver?.observe(document.documentElement, config)
    },
    clearResizeListener() {
      this.resizeObserver?.disconnect()
    },
    textBtnColor(item) {
      const COLOR = {
        '1': item.themeColor,
        '2': '#000',
        '3': '#fff'
      }
      if (item.bgFillType === 1) {
        return COLOR[`${item.fillTextColor}`]
      } else {
        return item?.textColor
      }
    },
    handleInitSwiperData() {
      if (!this.content?.length) return
      if (!this.showItems?.length) {
        this.resetNoData()
        return
      }
      this.loadComplete = true
    },
    resetNoData() {
      this.showItems = []
      this.loadComplete = false
    },
    // 计算各类高度值
    calculateBannerSize(config){
      // 以第一个配置项为基准
      const imageInfo = config?.image || {} // {height,width,src,ratio}
      
      if (!imageInfo.height) {
        // banner最小高度 UED设计0.6比例
        imageInfo.height = 10 * 0.6
        this.handleHideCompSls('CCCDataError', ErrorType.ItemImageHeightError, {
          image: config.image,
        })
      }
      // 根据新旧素材判断上移具体数值
      const moveUpMap = {
        'new': 1.18,
        'old': 1,
      }
      let moveUpHeight = config.FE_newImg ? (moveUpMap.new) : (moveUpMap.old)
      let realBannerHeight = imageInfo?.height * 10 / imageInfo?.width || 1
      // UED设计1.1比例
      const max_bannerHeight = 10 * 1.1
      if (realBannerHeight > max_bannerHeight) {
        // banner最大高度 
        // maxrealBannerHeight = max_bannerHeight
        realBannerHeight = max_bannerHeight
      }

      const topHeightInfo = this.propHooks?.calcTopHeight?.(realBannerHeight)

      return {
        ...topHeightInfo,
        moveUpHeight,
        realBannerHeight,
        bottomBgHeight: realBannerHeight - topHeightInfo.topBgHeight
      }
    },

    handleRealSwiperInit(swiper) {
      this.realSwiperInstance = swiper
      if (this.needSwiper && this.loadComplete && this.isShowComp) {
        nextTick(() => {
          typeof observer !== 'undefined' && observer.observe()
          this.swiperLoopAutoplayToNext(swiper)
        })
      }
      this.addResizeListener()
      cccxEventBus.on('page-scroll', this.onScroll, this.code)
    },
    clickProduct(params) {
      const goodsId = params.goods_id ?? ''
      this.handleSlideClick(goodsId)
    },
    // 处理swiper为loop时 事件绑定的问题
    handleSlideClick(goodsId) {
      const realIndex = this.getSwiperRealIndex()
      let item = this.showItems[realIndex]
      let goods_ids = item?.productList?.map(_i => _i.goods_id) || []
      if (goodsId && typeof goodsId === 'string') {
        goods_ids = [goodsId, ...goods_ids.filter(n => n !== goodsId)]
      }
      let goods
      if (goods_ids && Array.isArray(goods_ids) && goods_ids.length) {
        goods = goods_ids.join(',')
      }
      let url = this.cccLink.getFullLink({
        item: !goods_ids.length ? item : { ...item, adp: `${goods}` },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        isBff: true,
        index: (realIndex + 1)
      })
      if([10000, 10002, 10003].includes(+item.bannerType)){
        url += '&isAll=-1'
      }
      this.jumpLink({ url, hrefType: item.hrefType })
    },
    onScroll() {
      // 组件激活时会触发，非用户行为不用处理
      if (!isUserScroll) {
        isUserScroll = true
        return
      }
      // 切换路由时，Home.vue会强行修改showBlock=true
      if (!this.isCurrentChannelInstance) return
      const scrollThreshold = window.JOURNEY_BRANCH.close ? 4 : 78
      if (window.scrollY > scrollThreshold) {
        this.realSwiperBackTop = false
      } else {
        this.realSwiperBackTop = true
      }
    },
    getSwiperRealIndex() {
      const el = this.$refs.realBannerSwiper
      const activeSlideEl = el?.querySelector('.swiper-slide-active') || null
      const eleRealIndex = activeSlideEl?.getAttribute('data-realswiperIndex') || 0
      return Number(eleRealIndex)
    },
    // 进入视口时，恢复自动轮播
    handleEnterViewport() {
      this.inViewport = true
      if (this.needSwiper && this.realSwiperInstance && !this.realSwiperInstance?.autoplay?.running) {
        this.swiperLoopAutoplayToNext(this.realSwiperInstance, true)
      }
    },
    // 离开视口时停止自动轮播
    handleLeaveViewport() {
      this.inViewport = false
      this.pauseSwiperAutoplay()
    },
    playSwiperAutoplay() {
      // 折叠屏翻转时，realSwiperBackTop 可能为 false，这种情况应该暂停自动轮播
      if (!this.realSwiperBackTop) {
        this.pauseSwiperAutoplay()
        return
      }
      if (this.inViewport && !this.realSwiperInstance?.autoplay?.running) {
        this.realSwiperInstance?.autoplay?.start?.()
      }
    },
    pauseSwiperAutoplay() {
      this.realSwiperInstance?.autoplay?.stop?.()
    },
    swiperLoopAutoplayToNext(swiper) {
      this.realSwiperInstance = swiper
      this.playSwiperAutoplay()
    },
    // 埋点
    analysisProductItem(item) {
      const params = {
        ...item,
        DeepBannertype: item.bannerType
      }
      return params
    },
    analysisCateItem(item, burriedType) {
      const params = {
        ...item,
        DeepBannertype: item.bannerType
      }
      const productData = item.productList || []
      // 自动聚合样式曝光需要上报 goods_to_list 字段
      if (burriedType === 'expose' && AutoGoodsType.includes(+item.bannerType) && Array.isArray(productData) && productData.length) {
        params.needGoodsToList = true
      }
      // 自动聚合样式点击非商品区域, goods_to_list 上报为 '-'
      if (AutoGoodsType.includes(+item.bannerType) && burriedType === 'click') {
        params.goods_to_list = '-'
      }
      return params
    },
    initImmersiveBannerData(items = []) {
      if (!items?.length) {
        this.handleHideCompSls('CCCShownFilterError', ErrorType.ItemError)
        return []
      }
      
      if (items.find(item => !item.image && !item.backgroundImage)) {
        // 修复沉浸式组件未配置图片时带来的服务端渲染异常问题
        this.handleHideCompSls('CCCShownFilterError', ErrorType.ItemImageError)
        return []
      }
      const { HomeDiscount: homeDiscountNew } = this.context?.abtInfoObj ?? {}
      items.forEach((item, index) => {
        item.FE_ID = `${index}_${Math.random().toString().substring(2, 10)}`

        // 1.将所有img资源进行转换
        const bannerType = Number(item.bannerType)   
        if(bannerType === BANNER_TYPE.PureImage && item?.image?.src) {
          item.image.src = this.cutImg(item.image.src)
        }
        if (AutoGoodsType.includes(bannerType)) { // 商品聚合
          if (Boolean(+item.showBackgroundImage)) {
            item.image = item.backgroundImage
          }

          if (item?.image?.src) {
            item.image.src = this.cutImg(item.image.src)
          }

          if (item?.titleIconImage?.src) {
            item.titleIconImage.src = this.cutImg(item.titleIconImage.src)
          }

          let productList

          if (item?.productsV2) {
            productList = item.productsV2 || []
            // 同时删掉旧数据
            delete item.product_data
          } else {
            // 兜底
            productList = item.product_data?.products ?? []
          }

          productList.forEach(product => {
            product.goods_img = product.goods_img ? this.cutImg(product.goods_img) : product.goods_img
          })
          item.productList = productList
          if (!item.productList.length) {
            this.handleHideCompSls('CCCDataError', ErrorType.GoodsNumError, {
              bannerType: item.bannerType,
              cateId: item.cateId,
              cateType: item.cateType,
              hrefType: item.hrefType,
              hrefTarget: item.hrefTarget,
            })
          }
          if (item.themeColor) {
            item.bannerBgColor = `linear-gradient(to bottom, ${hexToRGBA(item.themeColor, 0.65)} 0%, ${hexToRGBA(item.themeColor, .90)} 100%)`
          }
        } else {
          const typeIncludes = Object.values(BANNER_TYPE).includes(bannerType)
          if (!typeIncludes) {
            this.handleHideCompSls('CCCDataError', ErrorType.TypeError, {
              bannerType: item.bannerType,
              cateId: item.cateId,
              cateType: item.cateType,
              hrefType: item.hrefType,
              hrefTarget: item.hrefTarget,
            })
          }
        }
        
        // UED给定，小于此参考比例为新素材，新素材就上移1.44rem(54 * 2 / 75), 旧素材就上移1.17rem(44 * 2 / 75)
        const referenceRatio = (1500 / 1090).toFixed(2)
        item.FE_newImg = +item?.image?.ratio < referenceRatio
        // 不处理gif banner，因为gif转webp以后，在safari浏览器会闪动
        item.homeDiscountNew = homeDiscountNew
      })

      // moveUpHeight, realBannerHeight, bottomBgHeight
      const commonHeight = this.calculateBannerSize(items[0])

      return { items, commonHeight }
    },
    // 异常日志上报
    handleHideCompSls(message, reason, extraInfo = {}) {
      this.hideCompSls({
        logLevel: 3,
        tag: 'web_client_home_error_total',
        message,
        reason,
        extraInfo
      })
      if (message === 'CCCShownFilterError') {
        // 隐藏组件告警
        this.hideCompMonitor({
          tags: {
            reason,
          }
        })
      }
    },
  }
})
</script>

<style lang="less">
.c-immersiveBanner-new__trans-placeholder {
  position: absolute;
  z-index: 11;
  width: 100%;
  bottom: -1px;
  height: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #fff;
}

/* stylelint-disable */
@vw: 375/100vw;
.c-immersiveBanner-new {
  &__area {
    overflow: hidden;
  }
  
  &__area.has-bottom-space {
    margin-bottom: 4 / @vw;
  }
  
  &__real { // 真实轮播区域
    z-index: 10;
    .trend-bottom__image {
      height: 1rem;
      position: absolute;
      z-index: 3;
      left: -8px;
      width: calc(100% + 16px);
      bottom: 0;
    }
  }
  &__real-swiper {
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  
  &__real-slide {
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
    position: relative;
  }
  &__list-recommend-out .immersiveBanner-products__product-item .list-style1__base-avatar {
    background: #ffffff;
  }
  &__wrap {
    background-color: #fff;
    position: relative;

    /deep/ .swiper-wrapper {
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      --webkit-backface-visibility: hidden;
      transform-style: preserve-3d;
    }

    /deep/ .swiper-slide {
        z-index: 1;
        --webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        perspective: 1000;
      }

    /deep/ .swiper-slide-active {
        z-index: 2;
    }
  }
  
  &__bgImage {
    position: relative;
    width: 10rem;
    z-index: 1;
    background-repeat: no-repeat; /* 垂直方向无缝重复 */
    background-size: 10rem auto; /* 设置背景图片宽度为100%，高度自适应 */
  }

  &__gif-banner {
    position: absolute;
    width: calc(10rem - 16px);
    z-index: @zindex-out;
    top: calc(var(--offset-top) + 0.0267rem);
    left: 0.2133rem;
  }

  &__bgLayer {
    height: 3.68rem;
    top: calc(var(--offset-top) + 0.0267rem);
    z-index: 10;
    pointer-events: none;
    left: 0.2133rem;
  }
  
  
  &__placeholder {
    &.has-bottom-space {
      margin-bottom: 4 / @vw;
    }
    
    .content {
      position: relative;
      width: 100%;
      
      .placeholder-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
  .base-img__inner{
    border-radius: 2px;
  }
  
}

.bg-index {
  width: 100%;
  height: 100%;
  background-color: aqua;
}
</style>
